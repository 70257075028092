export const ProgramasDos = (props) => {
    return (
      <div id="programasdos">
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <div className="about-text">
                <h2> El IPC División Geriatría, cuenta con equipo de enfermeros y un médico de Guardia disponible 24 horas dentro de sus instalaciones. La atención al servicio y cuidado del paciente, es permanente.</h2>
                <p>La patología psiquiátrica y neurológica de las personas mayores, pueden representar un reto para todos los agentes implicados. Las investigaciones han demostrado que vivir en centros especializados puede reducir los niveles de ansiedad y estrés de personas con patología, propios de un ambiente inadecuado. Luego, un residente en el sitio adecuado, podría reducir su necesidad de tratamiento clínico, al tiempo en que aumenta su salud y bienestar general, bajo el amparo de una serie de condiciones específicas y propias de la asistencia profesional: Nutrición adecuada, ejercicio físico, mayor interacción social, psicología, entre otras actividades dirigidas, sin duda conducen al tratamiento óptimo.</p>
                <p><b>Nuestro objetivo</b> es proporcionar un ámbito de oportunidades para que su ser querido, consiga experimentar una mejora de las habilidades  potenciales, tanto cognitivas como sociales; de este modo, una mejor calidad de vida.</p>
                <p>El enfoque sobre los cuidados y los tratamientos, es personalizado e incluye:</p>
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <ul>
                      <li>Psiquiatría</li>
                      <li>Psicología</li>
                      <li>Kinesiología</li>
                      <li>Nutrición profesional</li>
                      <li>Terapia Ocupacional</li>
                      <li>Arte</li>
                      <li>Yoga</li>
                      <li>Educación Física</li>
                    </ul>
                  </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <div className="about-text">
              </div>
            </div>
  
          </div>
        </div>
      </div>
      </div>
    );
  };
  