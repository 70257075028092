export const Programas = (props) => {
    return (
      <div id="programas">
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>Servicios asistenciales</h2>
                <p>Cada nuevo paciente trae consigo una serie de rutinas e intereses, o bien desea recuperarlas. La búsqueda de las capacidades y necesidades individuales debe transcurrir de modo que no altere el curso de una saludable adaptación al cambio, por lo cual nuestro equipo terapéutico apunta ante todo, a respetar la subjetividad.</p>
                <p>Nuestro equipo profesional transdisciplinario, al trabajar junto a la familia del nuevo paciente, buscará completar un plan de acción personalizado que incluya el aspecto médico, pero sin descuidar el humano. El objetivo institucional intentará establecer un ambiente saludable y de bienestar, que conste de actividades sociofamiliares que superen un objetivo clínico, pero asimismo, que no culmine en una serie de actividades indeseadas o inútiles para el residente.</p>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/about2.jpg" className="img-responsive" alt="" />{" "}
            </div>
          </div>
          <div className="row" id="listadito">
            <div className="list-style">
                  <div className="col-lg-6 col-sm-12 col-xs-12">
                    <h3>Cuidado y Atención</h3>
                    <ul>
                      <li>Médico de Guardia 24hs</li>
                      <li>Supervisión de Enfermería 24hs</li>
                      <li>Atención psiquiátrica y psicológica</li>
                      <li>Provisión de medicación</li>
                      <li>Controles clínicos diarios</li>
                      <li>Asistencia en actividades de la vida diaria</li>
                      <li>Actividad física - Yoga</li>
                      <li>Servicio de peluquería y podología</li>
                      <li>Servicio de emergencia</li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-xs-12">
                    <h3>Sociales y Recreativos</h3>
                    <ul>
                      <li>Programa semanal de actividades individuales y grupales</li>
                      <li>Festejo de cumpleaños</li>
                      <li>Celebración de eventos especiales</li>
                      <li>Shows en vivo</li>
                      <li>Salidas grupales</li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-xs-12">
                    <h3>Adicionales opcionales</h3>
                    <ul>
                      <li>Gestión de medicación y artículos varios.</li>
                      <li>Gestión de trámites: turnos médicos, estudios clínicos, judiciales.</li>
                    </ul>
                  </div>
                </div>
          </div>
        </div>
      </div>
      </div>
    );
  };
  