import { Image } from "./image";

export const Gallery = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Instalaciones</h2>
          <p>
          Un desarrollo edilicio óptimo, busca aminorar cualquier riesgo vinculado a la marcha. Tanto el mobiliario, como el circuito de circulación de nuestras instalaciones, conforman un objetivo específico: provocar estímulos determinados que consigan definir claramente cada parte de la residencia, orientando así a las personas internadas, dentro de la misma.
          </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-sm-6 col-md-4 col-lg-4'>
                  <Image /* title={d.title} largeImage={d.largeImage}*/ smallImage={d.smallImage} />
                </div>
              ))
              : 'Loading...'}
          </div>
        </div>
      </div>
    </div>
  )
}
