export const Features = (props) => {
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>IPC División Geriatría</h2>
          <p>
          Muchas de las personas que reciben un diagnóstico de enfermedad de Alzheimer o demencia con comorbilidades de índole psiquiátrica, consiguen continuar su vida dentro de su núcleo familiar primario, pero ello dependerá tanto del grado de afección padecido sobre la esfera cognitiva y volitiva, como del momento del curso del trastorno en que se halle.
          </p>
          <p>
          El IPC ofrece una solución permanente para la asistencia y residencia de quienes ya no puedan contar con la autonomía para desenvolverse por sí mismos, o incluso mediante la ayuda de sus personas cercanas. Nuestro ámbito, es un lugar de reencuentro permanente entre la persona y sus afectos.</p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3'>
                  {' '}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                {/*<p>{d.text}</p>*/}
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  )
}
