export const Services = (props) => {
  return (
    <div id='servicesdos' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Servicios Residenciales</h2>
          <p>
          Nuestra división geriatría, cuenta con un edificio diseñado exclusivamente: los ambientes y espacios comunes se componen de modo que los residentes puedan conservar -e incluso reestablecer- el máximo potencial de sus capacidades.
          </p>
        </div>
          <div className='row'>
          <div className="col-xs-12 col-md-6">
          <div className="about-text lista-servicios">
              <div className="list-style">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <ul>
                    <li>Gimnasio para rehabilitación</li>
                    <li>Amplio salón comedor panorámico</li>
                    <li>Áreas de descanso e integración </li>
                    <li>Servico de WIFi - TV cable</li>
                    <li>A/Acondiconado y Calefacción Central</li>
                    <li>Baños privados </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
          <div className="about-text lista-servicios">
              <div className="list-style">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <ul>
                    <li>Circuito de cámaras de seguridad</li>
                    <li>Consultorio privado </li>
                    <li>Jardín terraza exclusivo - Parrilla</li>
                    <li>Espacio para ejercitación </li>
                    <li>Servicio de lavandería</li>
                    <li>Camas Ortopédicas</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>        
      </div>
    </div>
  )
}
