export const Banner = (props) => {
    return (
      <div id='services-tres' className='text-center frase'>
        <div className='container'>
          <div className='section-title' id="text-banner">
            <h3>"... diagnosticar, implica una etiqueta, y no hay etiqueta que pueda representar la complejidad de un ser humano y su entorno."</h3>
            <p>- Adolf Meyer.</p>
          </div>
        </div>
      </div>
    )
  }
  